<script setup lang="ts">
import type { Code } from '@/types/locale.js'
import appSetting from '@/app-setting'

const store = useAppStore()
const { setLocale } = useI18n()

function changeLanguage(item: Code) {
  appSetting.toggleLanguage(item, setLocale)
}

const currentFlag = computed(() => {
  return `/flags/${store.locale?.toUpperCase()}.svg`
})
</script>

<template>
  <div class="dropdown shrink-0">
    <client-only>
      <Popper
        :placement="store.rtlClass === 'rtl' ? 'bottom-end' : 'bottom-start'"
        offset-distance="8"
      >
        <button
          type="button"
          class="block rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
        >
          <img
            :src="currentFlag"
            alt="flag"
            class="h-5 w-5 rounded-full object-cover"
          >
        </button>
        <template #content="{ close }">
          <ul
            class="flex items-center panel w-[280px] justify-around !px-2 font-semibold text-dark dark:text-white-dark dark:text-white-light/90"
            @click="close()"
          >
            <template
              v-for="item in store.languageList"
              :key="item.code"
            >
              <li>
                <button
                  type="button"
                  class="w-full flex items-center flex-col justify-center gap-2 hover:text-primary p-1 rounded-md"
                  :class="{ 'bg-primary/10 text-primary': store.locale === item?.code }"
                  @click="changeLanguage(item)"
                >
                  <img
                    class="h-5 w-5 rounded-full object-cover"
                    :src="`/flags/${item.code.toUpperCase()}.svg`"
                    alt=""
                  >
                  <span class="ltr:ml-3 rtl:mr-3">{{ item.name }}</span>
                </button>
              </li>
            </template>
          </ul>
        </template>
      </Popper>
    </client-only>
  </div>
</template>
